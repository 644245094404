import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PromiseTester from 'components/PromiseTester';

const appRouter = createBrowserRouter([
    {
        path: '/', 
        element: <div>Home</div>
    },
    {
        path: '/dashboard', 
        element: <div>Diagnostic Alerts</div>
    },
    {
        path: '/promiseTester',// this route is an example, should be removed before pushing to prod
        element: <PromiseTester />
    }
]);

export default appRouter;