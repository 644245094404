import React from 'react';

function LoadingSpinner(){
    // spinner from https://tw-elements.com/docs/react/components/spinners/
    return (
        <div className='flex flex-col items-center justify-center mt-4'>
          <div
    className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent"
    role="status">
        </div>
        <p className='mt-4 ml-2'>Loading ...</p>
        </div>
    );
}

export default LoadingSpinner;