/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import Highcharts from 'highcharts/highstock';
import './HighchartsTheme.css';

function InitializeHighcharts() {
  // Global Highcharts Options
  Highcharts.setOptions({
    accessibility: { 
      enabled: false
    },
    credits: {
      enabled: false
    },
    time: {
      // Enalysis stores datetimes in our asset's timezone, which is how we receive it, do we do not store datetimes in UTC
      useUTC: false
    }
  });
}

export default InitializeHighcharts;