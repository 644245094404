import React, {Suspense} from 'react';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import {Await} from 'react-router-dom';
import ErrorMessage from 'components/ErrorMessage';

function PromiseTester(){
    // messagePromise is meant to mock an api call
    const messagePromise = new Promise((res,rej) => setTimeout(() => res("hi"),10000));
    
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Await resolve={messagePromise}
            errorElement={<ErrorMessage />}
            >
                {(resolvedMessage) => <div>{resolvedMessage}</div>}
            </Await>
        </Suspense>
    );

}

export default PromiseTester;