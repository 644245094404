import { RouterProvider } from 'react-router-dom';
import appRouter from 'components/router/AppRouter';
import './App.css';

function App() {
  return (
    <RouterProvider router={appRouter} />
  );
}

export default App;